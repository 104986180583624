<template>
  <div class="confirm-email-container">
    <b-card class="text-center p-4">
      <h3 class="mb-4">Подтверждение Email</h3>
      <p v-if="confirmResult" class="mb-3 text-success" style="margin-top: -10px">Почта подтверждена.</p>
      <p v-else class="mb-3 text-success"></p>
      <p class="mb-3 text-muted">Мы отправили вам код на вашу почту. Введите его ниже для завершения регистрации.</p>
      <b-form-group label="Введите код подтверждения">
        <b-form-input
            v-model="confirmationCode"
            placeholder="Введите код"
            maxlength="10"
            class="text-center"
            size="lg"
            :state="validCode"
            required
        ></b-form-input>
      </b-form-group>
      <b-button
          variant="primary"
          size="lg"
          class="mt-3"
          @click="confirmEmail"
          :disabled="!validCode"
      >
        Подтвердить
      </b-button>

      <p class="mt-4">
        <small class="text-muted">Не получили код?
          <b-link @click="resendCode" class="text-primary">Отправить снова</b-link>
        </small>
      </p>

      <p v-if="errorMessage" class="text-danger mt-3">{{ errorMessage }}</p>
    </b-card>
  </div>
</template>


<script>
import {
  BCard,
  BFormGroup,
  BLink,
  BButton,
  BFormInput
} from 'bootstrap-vue';
import {mapActions} from 'vuex'


export default {
  name: 'ConfirmEmail',
  components: {
    BCard,
    BFormGroup,
    BLink,
    BButton,
    BFormInput
  },
  data() {
    return {
      confirmationCode: '',
      validCode: false,
      confirmResult: false,
      errorMessage: '',
    };
  },

  watch: {
    confirmationCode(val) {
      this.validateCode(val);
    }
  },

  methods: {

    ...mapActions('user', ['userConfirm']),
    validateCode(val) {
      this.validCode = val.length === 10;
      this.errorMessage = '';
    },

    async confirmEmail() {
      this.confirmResult = await this.userConfirm(this.confirmationCode);


      if (this.confirmResult) {
        this.$router.push('/login');
      } else {
        this.errorMessage = 'Неверный код, попробуйте снова.';
      }
    },
    resendCode() {
      alert('Код отправлен снова на ваш email.');
    }
  }
};
</script>

<style scoped>
.confirm-email-container {
  max-width: 400px;
  margin: 100px auto;
}
</style>
